/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isAndroid } from 'react-device-detect';
import DeepLinkModal from '../../components/DeepLinkModal';
import Main from '../../components/layout/Main';

// dynamic routes
const dynamicRoutes = [
    {
        webPattern: '^/horoscope/([^/]+)/([^/]+)$',
        mobilePath: 'horoscopeDetail/:timePeriod/:horoscopeSign',
    },
];

//  static routes
const staticMapping = {
    '/moonsign-select': 'moonsignSelect',
    '/payment-plan-select': 'subscription',
    '/consultation-form': 'consultationForm',
    '/consultations': 'consultations',
    '/gemstones': 'gemstones',
    '/life-events': 'lifeEvents',
    '/noticeboard': 'noticeboard',
    '/gemstone-enquiry': 'gemstoneEnquiry',
    '/my-subscriptions': 'subscriptionSetting',
    '/consultation-detail/:url?': 'consultationDetail',
};

/**
 * Looks for a dynamic route match, then falls back to the static mapping
 */
function getMobileAppRoute(redirectPath) {
    const matchedRoute = dynamicRoutes.find(route => {
        const regex = new RegExp(route.webPattern);
        return regex.test(redirectPath);
    });

    if (matchedRoute) {
        const { mobilePath } = matchedRoute;
        const regex = new RegExp(matchedRoute.webPattern);
        const match = redirectPath.match(regex);
        const placeholders = mobilePath.match(/:[^/]+/g) || [];
        let finalMobilePath = mobilePath;

        placeholders.forEach((placeholder, i) => {
            finalMobilePath = finalMobilePath.replace(placeholder, match[i + 1]);
        });

        return finalMobilePath;
    }

    return staticMapping[redirectPath] || '';
}

const DeepLinkHandler = () => {
    const location = useLocation();
    const history = useHistory();
    const { isLoggedIn } = useSelector((state) => state.user);
    const [showModal, setShowModal] = useState(false);
    const redirectPath = location.pathname.replace('/deeplink', '');

    const params = new URLSearchParams(location.search);
    const authParam = params.get('auth');
    const requiresAuth = authParam === 'true';

    const cleanParams = new URLSearchParams(location.search);
    cleanParams.delete('auth');
    const cleanDeepLinkURL = redirectPath + (cleanParams.toString() ? `?${cleanParams.toString()}` : '');

    const mobileAppRoute = getMobileAppRoute(redirectPath);

    useEffect(() => {
        window.sessionStorage.setItem('deepLinkRedirect', cleanDeepLinkURL);

        if (isAndroid) {
            setShowModal(true);
        } else if (requiresAuth) {
            if (isLoggedIn) {
                history.push(cleanDeepLinkURL);
            } else {
                history.push('/');
            }
        } else {
            window.sessionStorage.removeItem('deepLinkRedirect');
            history.push(cleanDeepLinkURL);
        }
    }, [cleanDeepLinkURL, location.search, isLoggedIn, history, requiresAuth]);

    return (
        <Main
            title={<div>Welcome</div>}
            headerTitle={(
                <div>
                    <img
                        style={{ height: 70, width: 70 }}
                        src="https://prakashastrologer-19e87.kxcdn.com/wp-content/uploads/2020/05/PrakashAstrologerLogo.svg"
                        alt="logo" />
                </div>
            )}
            showFooter={false}>
            {showModal && (
                <DeepLinkModal
                    onClose={() => setShowModal(false)}
                    requiresAuth={requiresAuth}
                    mobileAppRoute={mobileAppRoute} />
            )}
        </Main>
    );
};

export default DeepLinkHandler;
