import React from 'react';
import { Modal, Button } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import './deepLinkModal.scss';

const DeepLinkModal = ({ onClose, requiresAuth, mobileAppRoute }) => {
    const { isLoggedIn } = useSelector((state) => state.user);
    const history = useHistory();

    const openMobileApp = () => {
        let appLink = 'prakashastrology://';
        if (mobileAppRoute) {
            appLink += mobileAppRoute;
        }
        window.location.href = appLink;

        setTimeout(() => {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.prakash.astrology';
        }, 2000);
    };

    const handleContinueInBrowser = () => {
        const deepLinkRedirect = window.sessionStorage.getItem('deepLinkRedirect');
        if (!requiresAuth || isLoggedIn) {
            // window.sessionStorage.removeItem('deepLinkRedirect');
            history.push(deepLinkRedirect);
        } else {
            history.push('/');
        }
    };

    return (
        <Modal visible footer={false} onCancel={onClose}>
            <div className="deep-link-modal">
                <h2>How would you like to continue?</h2>
                <Button className="modal-btn" onClick={handleContinueInBrowser}>
                    Continue in the Browser
                </Button>
                <Button className="modal-btn" onClick={openMobileApp}>
                    Open in the Mobile App
                </Button>
            </div>
        </Modal>
    );
};

DeepLinkModal.propTypes = {
    onClose: PropTypes.func,
    requiresAuth: PropTypes.bool,
    mobileAppRoute: PropTypes.string,
};

DeepLinkModal.defaultProps = {
    onClose: () => {},
    requiresAuth: false,
    mobileAppRoute: '',
};

export default DeepLinkModal;
