import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Image, Button } from 'antd';
import { getTranslation, getSelectedTranslation } from '../../core/utils/helpers';
import useLanguage from '../../core/language/useLanguage';
import './displayMoonsign.scss';
import { resetMoonsignLookup } from '../../core/horoscope/horoscopeActions';

const DisplayMoonsign = ({ lookupType }) => {
    const { language } = useLanguage();
    const dispatch = useDispatch();

    const { searchedMoonsign } = useSelector((state) => state.horoscope);

    const translation = getSelectedTranslation(searchedMoonsign?.translations, language?.selectedLanguage?.id);

    const handleSearchAgainClick = () => {
        dispatch(resetMoonsignLookup());
    };

    // Define keys and default texts based on the lookupType prop
    const titleKey = lookupType === 'rising' ? 'rising_sign_generate_text_title' : 'moonsign_generate_text_title';
    const defaultTitle = lookupType === 'rising'
        ? 'We have generated your Rising Sign'
        : 'We have generated your Moonsign';

    const buttonKey = lookupType === 'rising' ? 'search_another_rising_sign_button_title' : 'search_another_moonsign_button_title';
    const defaultButtonText = lookupType === 'rising'
        ? 'Search Another Rising Sign'
        : 'Search Another Moonsign';

    return (
        <div className="display-moonsign-container">
            <h1 className="title-text">
                {getTranslation(language, titleKey, defaultTitle)}
            </h1>
            <Image
                preview={false}
                height={354}
                width={337}
                src={`${searchedMoonsign?.mediaUrl}`} />
            <p className="sub-title-text" style={{ fontFamily: 'Inter' }}>
                {translation?.name}
            </p>
            <Button
                type="primary"
                style={{ marginBottom: 10 }}
                className="ant-btn-yellow"
                onClick={handleSearchAgainClick}>
                {getTranslation(language, buttonKey, defaultButtonText)}
            </Button>
        </div>
    );
};

DisplayMoonsign.propTypes = {
    lookupType: PropTypes.string,
};

DisplayMoonsign.defaultProps = {
    lookupType: 'moonsign',
};

export default DisplayMoonsign;
